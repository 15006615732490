$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

popular-cities {
  > .row {
    text-align: center;
    a {
      display: block;
      text-decoration: underline;

      &:hover, &:hover + a {
        text-decoration: none;
      }

      > img {
        width: 100%;
        max-height: 150px;
        max-width: 150px;
        @media (max-width: 768px) {
          max-height: 100px;
          max-width: 100px;
        }
        border-radius: 50%;
        border: 7px solid rgba(0, 0, 0, .45);
        transition: all 100ms linear;

        &:hover {
          border-color: $like;
        }
      }
    }
  }
}
