$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";
$navbar-zindex: $zindex-sticky;

.navbar-skipped-anchor:before {
  content: "";
  display: block;
  height: 3.5rem; /* fixed header height*/
  margin: -3.5rem 0 0; /* negative fixed header height */
}

mini-navbar > nav > *, hostel-navbar > nav > .container > *, likehostel-navbar > nav > .container > * {
  display: inline-block;
  vertical-align: top;
}

hostel-navbar, likehostel-navbar, mini-navbar {
  > nav, .navbar-overlay {
    height: 3.5rem;
  }
  > nav {
    position: fixed;
    width: 100%;
    z-index: $navbar-zindex;
    background-color: white;
  }

  > nav, .likehostel-logo {
    border-bottom: 1px solid #e1e1e1;
  }

  .likehostel-logo-navbar, .likehostel-logo {
    width: 85px;
  }

  .likehostel-logo-navbar {
    position: relative;
    top: -5px;
  }

  .likehostel-logo {
    border-radius: 50%;
    background-color: white;
    height: 85px;
    background-size: 70px 70px;
    position: absolute;
    margin-top: 0;
    z-index: $navbar-zindex + 1;
  }

  .navbar-hostel-phone, a, a:hover {
    font-weight: bold;
    color: black;
  }

  a, a:hover {
    text-transform: uppercase;
  }
  a {
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: underline !important;
  }

  ul {
    padding: 0;
    list-style: none;
    > li {
      display: inline-block;
    }
  }

  a, ul, .navbar-hostel-phone {
    margin: 14px 0 0;
  }

  .navbar-hostel-phone {
    display: inline-block;
  }
  .float-right > .btn {
    margin-left: 20px;
  }

  .navbar-toggler {
    color: $like;
    font-size: 1.7em;
    margin-left: 10px;
    position: relative;
    top: 5px;
  }
}

hostel-navbar, likehostel-navbar {
  .likehostel-logo {
    margin-left: 0;
  }
  a {
    margin-left: 20px;
  }

  .navbar-menu-background {
    display: none;
  }

  > nav > .container > ul > li:last-child {
    display: none;
  }

  @media (max-width: 990px) {
    > nav > .container {
      > ul, > .float-right > .btn {
        display: none;
      }
    }
    > nav > .container > ul.active {
      display: block;
      background-image: url("assets/img/pattern.png");
      width: 50%;
      max-width: 200px;
      height: 100vh;
      position: absolute;
      top: 55px;
      margin-top: 0;
      padding-top: 40px;
      border-right: 1px solid #e1e1e1;
      z-index: $navbar-zindex - 1;
      left: -200px;
      @keyframes hostel-navbar-menu-slide {
        100% {
          left: 0;
        }
      }
      animation: hostel-navbar-menu-slide 0.5s forwards;

      > li {
        display: block;
        &:last-child {
          display: block;
          text-align: center;
          margin-top: 20px;
        }
      }
    }

    .navbar-menu-background.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: $navbar-zindex - 2;
      background-color: rgba(0, 0, 0, 0);
      @keyframes hostel-navbar-menu-background {
        100% {
          background-color: rgba(0, 0, 0, .5);
        }
      }
      animation: hostel-navbar-menu-background 0.5s forwards;
    }
  }
}
