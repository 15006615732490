$staticUrl: 'https://static.likehostels.ru';
cities-list {
  ul.cities-list {
    &, > li > ul {
      list-style: none;
      padding: 0;
    }

    li.cities-list-letter {
      font-weight: bold;
    }
  }
}
