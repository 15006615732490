$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

hostel-whats-in {
  .hostel-whats-in {
    background-color: $like;
    color: white;
    position: relative;
    text-align: center;

    &:before, &:after {
      content: "";
      background-image: url("assets/img/riffle.png");
      height: 13px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
    }
    &:before {
      top: -13px;
    }
    &:after {
      top: 100%;
      transform: rotate(180deg);
    }

    .lh-whats-in-clean, .lh-whats-in-parking, .lh-whats-in-kid, .lh-whats-in-comfort, .lh-whats-in-wifi, .lh-whats-in-services {
      display: inline-block;
      width: 70px;
      height: 70px;
    }

    .lh-whats-in-clean {
      background-image: url("assets/img/index-whats-in/clean.png");
    }
    .lh-whats-in-parking {
      background-image: url("assets/img/index-whats-in/parking.png");
    }
    .lh-whats-in-kid {
      background-image: url("assets/img/index-whats-in/kid.png");
    }
    .lh-whats-in-comfort {
      background-image: url("assets/img/index-whats-in/comfort.png");
    }
    .lh-whats-in-wifi {
      background-image: url("assets/img/index-whats-in/wifi.png");
    }
    .lh-whats-in-services {
      background-image: url("assets/img/index-whats-in/services.png");
    }
  }
}
