$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

city-search {
  .dropdown-menu {
    margin: 0;
    left: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 30px);
    top: calc(100% - 1px);
    padding: 1px 0 0;
    border: 1px solid #ced4da;
    max-height: 200px;
    overflow-y: auto;

    .dropdown-item {
      padding-left: 13px;

      .fa-map-marker-alt {
        color: $like;
        padding-right: 15px;
      }

      &.active {
        background-color: $like;
        &, .fa-map-marker-alt {
          color: white;
        }
      }
    }
  }

  .input-group-text {
    background-color: white;
    padding-right: 0;
    color: gray;
  }

  .form-control {
    border-left: none;
    &:focus {
      border-color: #ced4da;
      box-shadow: none;
    }
  }

  .form-control.border-bottom-0, .input-group-text.border-bottom-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  form {
    padding: 1rem;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 5px;

    .form-group {
      margin-bottom: 0;
    }
  }
}
