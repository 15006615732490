$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";
$progress-zindex: $zindex-sticky + 1;

progressbar {
  .progress {
    position: fixed;
    z-index: $progress-zindex;
    width: 100%;
    height: 5px;
    border-radius: 0;
    opacity: 0;
    transition: opacity 500ms;
    &.show {
      opacity: 1;
    }
  }
}
