$staticUrl: 'https://static.likehostels.ru';
vacancy-vacancy {
  h1 {
    font-weight: 400;
    font-size: 2em;
  }
  h2 {
    font-weight: 300;
    font-size: 1.5em;
  }
  h1.vacancies-other {
    font-weight: 300;
  }
  a.vacancy-company {
    font-size: 1.5em;
    text-decoration: none;
  }
}
