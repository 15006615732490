$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

error404 {
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("#{$staticUrl}/img/cityscreen-v1.jpg") fixed;
    background-size: cover;
    content: "";
    z-index: -1;
  }

  position: relative;
  display: block;
  background-color: rgba(0, 0, 0, .25);

  .jumbotron {
    > h1, > h2, > p {
      text-align: center;
    }
  }

  cities-list {
    &, a, a:hover {
      color: white;
    }
  }

  popular-cities {
    a, a:hover {
      color: black;
    }
  }
}
