$staticUrl: 'https://static.likehostels.ru';
/* Show it is fixed to the top */
body {
  font-family: Source Sans Pro, sans-serif;
}

body/*, mini-navbar > nav , hostel-navbar > nav*/ {
  min-width: 410px;
}

a:not(.btn) {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.likehostel-logo {
  background-image: url("assets/img/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
  display: inline-block;
}

img.img-spinner {
  background: gainsboro url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///4iIiOLi4qampoiIiLW1tcTExMvLyyH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==) no-repeat center;
}

.flag-russia, .flag-belorussia, .flag-ukraine, .flag-kazakhstan, .flag-turkmenistan {
  background-repeat: no-repeat;
}

.flag-russia, .flag-ru {
  background-image: url("~flag-icon-css/flags/4x3/ru.svg");
}

.flag-belorussia, .flag-by {
  background-image: url("~flag-icon-css/flags/4x3/by.svg");
}

.flag-ukraine, .flag-ua {
  background-image: url("~flag-icon-css/flags/4x3/ua.svg");
}

.flag-kazakhstan, .flag-kz {
  background-image: url("~flag-icon-css/flags/4x3/kz.svg");
}

.flag-turkmenistan, .flag-tm {
  background-image: url("~flag-icon-css/flags/4x3/tm.svg");
}
