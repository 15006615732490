$staticUrl: 'https://static.likehostels.ru';
footer {
  .footer-bg, .footer-shadow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .footer-bg {
    background-size: cover;
    background-position-y: center;
    filter: blur(5px);
    z-index: -2;

  }
  .footer-shadow {
    z-index: -1;
    background-color: rgba(0, 0, 0, .25);
  }

  z-index: 0;
  position: relative;

  &, a, a:hover {
    color: white;
  }

  h4 {
    font-size: 1.5em;
    font-weight: 300;
  }

  .footer-social {
    a, a:hover {
      margin-right: 5px;
      text-decoration: none;
    }
    .fab {
      width: 35px;
      height: 35px;
      font-size: 25px;
      text-align: center;
      padding-top: 5px;
      border-radius: 5px;
    }
    .fa-vk {
      background-color: #45668e;
    }
    .fa-instagram {
      background-color: #3f729b;
    }
    .fa-facebook-f {
      background-color: #3b5998;
    }
    .fa-twitter {
      background-color: #55acee;
    }
  }

  .footer-subscribe {
    max-width: 250px;
    float: right;
    clear: right;
  }

  @media (max-width: 575px) {
    .container > .row > div {
      &, &.text-right {
        text-align: center !important;
      }
    }
    .footer-subscribe {
      float: inherit;
      clear: inherit;
      margin: 0 auto;
    }
  }

  .likehostel-copyright {
    position: absolute;
    bottom: 24px;

    @media (max-width: 991px) {
      > span {
        display: block;
      }
    }

    @media (max-width: 575px) {
      > span {
        display: inline;
      }
      text-align: center;
      bottom: 10px;
      width: 100%;
    }
  }

  .footer-phone {
    font-size: 1.5em;
  }

}
